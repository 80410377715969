// @flow

import classNames from 'classnames';
import React from 'react';
import type { Node } from 'react';
import { isTouchDevice } from '@riseart/fe-utils';
import { Button } from '@riseart/common';
import {
  shortcutLinksCls,
  shortcutLinksDraggableCls,
  shortcutLinksContainerCls,
} from 'shared_components/common/links/Shortcut.less';

type Props = {
  buttonProps?: Object,
  links?: Object[],
};

/**
 * ShortcutLinks
 *
 * @param {Props} props
 * @returns {Node}
 */
export function ShortcutLinks({ buttonProps = null, links }: Props): Node {
  if (!links || !links.length) {
    return null;
  }

  return (
    <div className={classNames(shortcutLinksCls, isTouchDevice() && shortcutLinksDraggableCls)}>
      <div className={shortcutLinksContainerCls}>
        {links.map((link, key) => (
          <Button
            key={key}
            type="secondary"
            size="medium"
            isAnchor
            {...buttonProps}
            external={!!link.external}
            href={link.href}
          >
            {link.text}
          </Button>
        ))}
      </div>
    </div>
  );
}
